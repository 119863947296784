import React, { useState, useEffect, useCallback } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Info = ({ children }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    if (infoOpen) {
      const f = () => setInfoOpen(false);
      window.addEventListener("click", f);

      return () => window.removeEventListener("click", f);
    }
  }, [infoOpen]);

  const clickHandler = useCallback((ev) => {
    setInfoOpen((v) => !v);
    ev.stopPropagation();
  });
  return (
    <>
      <div
        className={infoOpen ? "InfoIcon InfoIcon--open" : "InfoIcon"}
        onClick={clickHandler}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </div>
      {infoOpen && (
        <div className="InfoOverlay" onClick={() => setInfoOpen(false)}>
          <div className="BigInfo">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
          {children}
        </div>
      )}
    </>
  );
};
