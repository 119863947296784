const defaultSettings = {
  startDate: "2021-01-01",
  endDate: "2022-06-01",
  timeInterval: "month",
  useDurationSlider: false,
  showPostCount: false,
  // Hide the time slider
  hideTimeSlider: false,
  // Defines if the selected nodes pillbox should appear after grouping or after the graph
  moveNodeSelectionToBottom: false,
  // Hide the selected nodes pillbox anyway
  hideNodeSelection: false,
  //timeSlider marks if set manually
  timeSliderMarks: undefined,
  // Hide fullTimeOverview
  hideFullTimeOverview: undefined,
  // Hide everything but network
  networkOnly: undefined,
  // Download positions after render
  downloadAutoForceLayoutPositions: false,

  // Settings send directly to sigmajsjs
  sigmaSettings: {
    labelDensity: 0.5,
  },

  network: {
    // should clusterlabels be drawn
    drawClusterLabels: true,
    // Size settings for nodes
    sizeMultiplier: 4,
    sizeMinimum: 1,
    edgeSizeMultiplier: 2,
    edgeSizeMinimum: 0.1,
    zoomLevel: 0.02,
    // Algorithm to calculate in|out|inout|undefined=in,
    nodeSizeAlgorithm: "in",
    // if the program should alter the input sizes
    // if set to false only sizeMultiplier is used to resize nodes
    resizeNodes: true,

    // Implement mirko Aliasing instead of real antialiasing or whatever
    // makes small edges darker than larger edges
    mirkoAliasingMaxDarken: 5,
    mirkoAliasingFactor: 5,
    mirkoAliasingEnabled: true,
  },

  forceLayout: {
    autoRunFor: 10000,
  },

  // All different tag streams
  summaryData: {
    Topics_per_month: { label: "Themen", format: "percent" },
    Persons: { label: "Personen", format: "count" },
    Locations: { label: "Orte", format: "count" },
    Media_per_month: { label: "Medientyp", format: "percent" },
    Domain: { label: "Domains", format: "count" },
  },
  // First tab showing on page load
  defaultSummary: "Topics_per_month",
  // Number of topics shown
  topicLimit: 10,

  // Special config for dashboard
  dashboard: {
    // Actors that will be shown in the special header board
    actors: [
      [1, "TeamHeimatGruppe"],
      [2, "ZorroKenji"],
      [3, "EurasiaCoupleOffiziell"],
    ],
    // Groups that will be shown in the special place in the header
    groups: {
      first: {
        id: "first",
        name: "First Group",
        members: [
          "eingeSCHENKt",
          "AntiSpiegel",
          "krautzone",
          "horizont_telegram",
        ],
        description: "Erste gruppe erklörung",
      },
      second: {
        id: "second",
        name: "Second Group",
        members: [
          "freie_erzgebirger",
          "HartesGeld",
          "opposition24com",
          "report24news",
          "twanie47",
          "Studenten_Stehen_Auf",
          "Nuoviso",
        ],
        description: "Zweite gruppe erklörung",
      },
    },
  },
};

export default defaultSettings;
