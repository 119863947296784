import { useState, useCallback } from "react";

export const useKeyedState = (currentKey, defaultValue) => {
  // elsint-disable-next-line react-hooks/exhaustive-deps
  const memoDefaultValue = useCallback(defaultValue, []);
  const [keyedState, setKeyedState] = useState({});
  const state =
    keyedState[currentKey] !== undefined
      ? keyedState[currentKey]
      : memoDefaultValue;
  const setState = useCallback(
    (state) =>
      setKeyedState((oldState) => ({
        ...oldState,
        [currentKey]:
          typeof state === "function"
            ? state(
                oldState[currentKey] !== undefined
                  ? oldState[currentKey]
                  : memoDefaultValue
              )
            : state,
      })),
    [currentKey, setKeyedState]
  );
  return [state, setState];
};
