import React, { useMemo } from "react";
import { ResponsiveStream } from "@nivo/stream";
import { palette } from "/src/configs/nordsonne";
import { Translation, useTimedMemo } from "/src/utils";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export const PostCount = ({ data: list }) => {
  const data = useTimedMemo("postcount_data",
    () => list.map((value) => ({ postCount: value })),
    [list]
  );

  return (
    <ResponsiveStream
      data={data}
      label={(layer) => <Translation k="postCount" />}
      valueFormat={(value) => value.toLocaleString()}
      keys={["postCount"]}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridY={false}
      curve="basis"
      offsetType="none"
      colors={palette}
      colorBy="index"
      fillOpacity={0.85}
      borderColor={{ theme: "background" }}
    />
  );
};
