const baseColors = {
  first: {
    basic: "#37d2be",
    lighter: "#82d7c3",
  },
  second: {
    basic: "#7c4ee4",
    lighter: "#aa91e6",
  },
  third: {
    basic: "#fa1e5a",
    lighter: "#ff91af",
  },
  fourth: {
    basic: "#ffbe00",
    lighter: "#ffdc7d",
  },
  fifth: {
    basic: "#64a0ff",
    lighter: "#a5c8ff",
  },
  sixth: {
    basic: "#bef54b",
    lighter: "#d7fa91",
  },
  seventh: {
    basic: "#ff873c",
    lighter: "#ffaa8c",
  },
  black: "#0a0014",
  background: "#323241",
  gray: {
    basic: "#83839c",
    lighter: "#9c9cb0",
  },
};

export const colors = {
  ...baseColors,
  highlight: baseColors.third,
  palette: [
    baseColors.first.basic,
    baseColors.second.basic,
    baseColors.third.basic,
    baseColors.fourth.basic,
    baseColors.fifth.basic,
    baseColors.sixth.basic,
    baseColors.seventh.basic,
    baseColors.first.lighter,
    baseColors.second.lighter,
    baseColors.third.lighter,
    baseColors.fourth.lighter,
    baseColors.fifth.lighter,
    baseColors.sixth.lighter,
    baseColors.seventh.lighter,
  ],
};

export const palette = colors.palette;
