import React, { useMemo, useContext, useRef, useState, useEffect } from "react";
import { Translation } from "/src/utils";
import { DashboardCrash } from "./DashboardCrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { defaultSettings, defaultTranslations } from "/src/configs";

export const DataContext = React.createContext({});
const filesToFetch = [
  "channels.json",
  "network.json",
  "settings.json",
  "translations.json",
  "topics.json",
  "ideologys.json",
];

const fetchFile = ({ name, fileName, setState, setGotError }) =>
  fetch(fileName)
    .then((response) => { console.time("parse_" + name); const j = response.json(); console.timeEnd("parse_" + name); return j})
    .catch((error) => {
      console.log(error);
      console.log(`Error happend in file ${fileName}`);
      setGotError(true);
    })
    .then((json) => {
      console.log("SetState", name);
      setState((data) => {console.time("set_" + name);const a= ({ ...data, [name]: json }); console.timeEnd("set_" + name); return a;});
      console.timeEnd("loading_" + name);
    });

const _defaultDataLoaderPrefix =
  import.meta.env.REACT_APP_DATALOADER_PREFIX || "data/default/";

export const DataLoader = ({ prefix = _defaultDataLoaderPrefix, fileList=filesToFetch, children }) => {
  const [data, setData] = useState({});
  const [gotError, setGotError] = useState(false);

  useEffect(() => {
    console.log("start loading");
    for (let fileName of fileList) {
      const name = fileName.substring(0, fileName.length - 5);
      console.time("loading_" + name);
      fetchFile({
        name,
        fileName: prefix + fileName,
        setState: setData,
        setGotError: setGotError,
      });
    }
  }, [prefix]);

  if (gotError) {
    return <DashboardCrash />;
  }

  if (Object.keys(data).length < fileList.length) {
    return (
      <div className="DataLoader">
        <div className="DataLoader__Text">
          <FontAwesomeIcon icon={faCircleNotch} spin />
          <Translation k="base.loading" />
        </div>
      </div>
    );
  }
  console.log("Loaded");
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export const useFile = (fileName) => {
  const [data, setData] = useState(null);
  const [gotError, setGotError] = useState(false);
  const prefix = _defaultDataLoaderPrefix;

  useEffect(() => {
    fetch(prefix + fileName)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
        console.log(`Error happend in file ${fileName}`);
        setGotError(true);
      })
      .then((data) => {
        setData(data);
      });

    return () => {
      setData(null);
      setGotError(false);
    };
  }, [fileName]);

  return [data, gotError];
}

export const addPromiseState = (promise) => {
  promise.state = "loading";
  promise.then((result) => {
    promise.state = "fullfilled";
  });
  promise.catch((result) => {
    promise.state = "error";
  });
  return promise;
}

export const useFiles = (fileEntries) => {
  const [data, setData] = useState({});
  const [loadingState, setLoadingState] = useState([]);
  const [gotError, setGotError] = useState(false);
  const prefix = _defaultDataLoaderPrefix;

  useEffect(() => {
    console.log("FIleEntries", fileEntries);
    // TODO add some channcelation here
    // otherwise old promises might load into new ones!
    const loadingState = fileEntries.map(([key, fileName]) => {
      const promise = addPromiseState(fetch(prefix + fileName)
        .then((response) => response.json())
        .catch((error) => {
          console.log(error);
          console.log(`Error happend in file ${fileName}`);
          setGotError(true);
        })
        .then((data) => {
          setData((oldData) => ({...oldData, [key]: data}));
          return data;
        }));
      return [key, promise];
    });
    setLoadingState(loadingState);

    return () => {
      setData({});
      setGotError(false);
    };
  }, [fileEntries]);

  const loading = loadingState.filter(([key, promise]) => promise.state === "loading").length !== 0;
  console.log("Loading State", loading, loadingState.filter(([key, promise]) => promise.state !== "fullfilled"));
  return [data, loading];
}


export const useData = () => useContext(DataContext);

export const useChannelInfos = () => {
  const data = useData();
  return data["channel_infos"];
};

export const useChannels = () => {
  const data = useData();
  return data["channels"];
}

export const useNetwork = () => {
  const data = useData();
  return data["network"];
};

export const useSettings = () => {
  const data = useData();
  return useMemo(
    () =>
      data["settings"]
        ? { ...defaultSettings, ...data["settings"] }
        : defaultSettings,
    [data]
  );
};

export const useTranslations = () => {
  const data = useData();
  return useMemo(
    () =>
      data["translations"]
        ? { ...defaultTranslations, ...data["translations"] }
        : defaultTranslations,
    [data]
  );
};

export const useTopics = () => {
  const data = useData();
  return useMemo(() => data["topics"] || {}, [data]);
};

export const useIdeologys = () => {
  const data = useData();
  return useMemo(() => data["ideologys"] || {}, [data]);
};
