import {useMemo} from "react";


export const timerHoc = (name, Comp) => (
  (props) => {
    console.time(name);
    const result = <Comp {...props}/>
    console.timeEnd(name);
    return result;
  }
)

export const timerWrap = (name, func) => (
  () => {
    console.time(name);
    const a = func()
    console.timeEnd(name);
    return a;
  }
);

export const useTimedMemo = (name, func, params) => {
  console.time(name);
  const result = useMemo(func, params);
  console.timeEnd(name);
  return result;
}
