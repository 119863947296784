import React from "react";
import { useTranslations } from "/src/utils";
import ReactMarkdown from "react-markdown";

export const Translation = ({ k, markdown = false }) => {
  const translations = useTranslations();

  if (translations && translations[k]) {
    return translations[k];
  } else if (markdown) {
    return <ReactMarkdown>{translations[k]}</ReactMarkdown>;
  } else {
    return <div className="TranslationNotFound">{k}</div>;
  }
};
