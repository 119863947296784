import React, { memo, useMemo } from "react";
import { ResponsiveStream } from "@nivo/stream";
import { useTopics, useTimedMemo } from "/src/utils";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

import { useCallback, createElement } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useAnimatedPath, useMotionConfig } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";
import { BasicTooltip } from "@nivo/tooltip";

const LayerTooltip = ({ layer }) => (
  <BasicTooltip id={layer.label} enableChip={true} color={layer.color} />
);

export const StreamLayers = ({ layers, onClick, ...props }) => {
  return (
    <g>
      {layers.map((layer, i) => (
        <StreamLayer
          key={i}
          layer={layer}
          getBorderColor={() => "black"}
          borderWidth={0}
          fillOpacity={1}
          isInteractive={true}
          tooltip={LayerTooltip}
          onClick={(ev) => onClick(layer.id)}
        />
      ))}
    </g>
  );
};

export const StreamLayer = ({
  layer,
  fillOpacity,
  borderWidth,
  getBorderColor,
  isInteractive,
  tooltip,
  onClick,
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const handleMouseHover = useCallback(
    (event) => {
      showTooltipFromEvent(createElement(tooltip, { layer }), event, "left");
    },
    [showTooltipFromEvent, layer, tooltip]
  );

  const { animate, config: springConfig } = useMotionConfig();
  const animatedPath = useAnimatedPath(layer.path);
  const animatedProps = useSpring({
    color: layer.color,
    config: springConfig,
    immediate: !animate,
  });

  return (
    <animated.path
      d={animatedPath}
      fill={layer.fill ? layer.fill : animatedProps.color}
      fillOpacity={fillOpacity}
      stroke={getBorderColor(layer)}
      strokeWidth={borderWidth}
      onMouseMove={isInteractive ? handleMouseHover : undefined}
      onMouseEnter={isInteractive ? handleMouseHover : undefined}
      onMouseLeave={isInteractive ? hideTooltip : undefined}
      onClick={onClick}
    />
  );
};

// TODO this is exactly the same code as in TopicsClickBurst
const burstify = ({ allTopics, topicMetaData }) => {
  const total = allTopics.reduce((a, [k, v]) => a + v, 0);
  const lvl3 = Object.fromEntries(
    allTopics
      .map(([key, value]) => [
        key,
        {
          name: topicMetaData[key].LV3,
          id: topicMetaData[key].LV3,
          label: topicMetaData[key].LV3,
          value: value / total,
          ...topicMetaData[key],
        },
      ])
      .sort((a, b) =>
        a["Order1"] < b["Order1"]
          ? -1
          : a["Order1"] > b["Order1"]
          ? 1
          : a["Order2"] - b["Order2"]
      )
  );

  let lvl2 = {};
  for (let v of Object.values(lvl3)) {
    const k = v["LV2"];
    lvl2[k] = lvl2[k] ? [...lvl2[k], v] : [v];
  }
  let lvl1 = {};
  for (let [k2, v] of Object.entries(lvl2)) {
    const k = v[0]["LV1"];
    const v2 = {
      name: k2,
      id: k2,
      label: k2,
      children: v,
      value: v.reduce((a, v2) => a + v2.value, 0),
      color: v[0]?.color,
      color2: v[0]?.color2,
      color3: v[0]?.color3,
      Order2: v[0]?.Order2,
      Order1: v[0]?.Order1,
    };
    lvl1[k] = lvl1[k] ? [...lvl1[k], v2] : [v2];
  }

  const data = {
    name: "nivo",
    children: Object.entries(lvl1)
      .map(([key, value]) => ({
        name: key,
        id: key,
        label: key,
        children: value.sort((a, b) => a["Order2"] - b["Order2"]),
        value: value.reduce((a, v2) => a + v2.value, 0),
        color: value[0]?.color,
        color2: value[0]?.color2,
        color3: value[0]?.color3,
        Order1: value[0]?.Order1,
      }))
      .sort((a, b) => a["Order1"] - b["Order1"]),
  };
  return data;
};

export const useClickStreamTopics = ({
  topics: allTopics2,
  monthBased,
  level,
  setLevel,
}) => {
  const topicMetaData = useTopics();
  const topicsPerMonth = useTimedMemo("cst_per_month",
    () =>
      Object.fromEntries(
        Object.entries(monthBased).map(([key, allTopics]) => [
          key,
          burstify({ allTopics: Object.entries(allTopics), topicMetaData }),
        ])
      ),
    [monthBased, topicMetaData]
  );

  const cdata = useTimedMemo("cst_cdata",
    () =>
      Object.fromEntries(
        Object.entries(topicsPerMonth).map(([date, topics]) => {
          let cdata = [];

          if (level.length >= 0) {
            cdata = topics.children;
          }
          if (level.length >= 1) {
            cdata = cdata.find((child) => child.id === level[0]).children;
          }
          if (level.length >= 2) {
            cdata = cdata.find((child) => child.id === level[1]).children;
          }
          return [date, cdata];
        })
      ),
    [topicsPerMonth, level]
  );

  const sdata = useTimedMemo("cst_sdata",
    () =>
      Object.entries(cdata).map(([date, data]) =>
        Object.fromEntries(data.map((obj) => [obj.label, obj.value]))
      ),
    [cdata]
  );

  const tags = useTimedMemo("cst_tags",
    () =>
      Object.entries(sdata[0] ? sdata[0] : {})
        .sort(([keyA, a], [keyB, b]) => {
          return a["Order1"] < b["Order1"]
            ? -1
            : a["Order1"] > b["Order1"]
            ? 1
            : a["Order2"] - b["Order2"];
        })
        .map(([key, o]) => key),
    [sdata]
  );
  const colors = useTimedMemo("cst_colors",
    () => {
      try{
        return Object.fromEntries(
          Object.entries(cdata[Object.keys(cdata)[0]]).map(([key, o]) => [
            o.label,
            [o.color, o.color2, o.color3][level.length],
          ])
        );
      }
      catch(e){
        // TODO fix this anyway
        console.log(e);
        return {}
      }
    },
    [cdata, level]
  );

  return {
    level,
    setLevel,
    cdata,
    tags,
    sdata,
    topicMetaData,
    colors,
  };
};

export const TopicsClickStream = ({
    data: monthBased,
    tag,
    limit = 10,
    topicOrder,
    topics: allTopics,
    level,
    setLevel,
  }) => {
    const { tags, sdata, colors } = useClickStreamTopics({
      topics: allTopics,
      monthBased,
      level,
      setLevel,
    });

    return (
      <ResponsiveStream
        data={sdata}
        keys={tags}
        colors={(d) => colors[d.id]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: 36,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: -40,
        }}
        enableGridY={false}
        enableGridX={true}
        curve="basis"
        offsetType="silhouette"
        fillOpacity={0.85}
        borderColor={{ theme: "background" }}
        textColor="black"
        valueFormat=">-#.1%"
        layers={[
          "grid",
          "axes",
          (props) => (
            <StreamLayers
              {...props}
              onClick={(layerId) => {
                setLevel((l) => (l.length < 2 ? [...l, layerId] : l));
              }}
            />
          ),
          "slices",
        ]}
      />
    );
  };
