import React, { useState, useMemo, useCallback, memo } from "react";
import dayjs from "dayjs";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { PostCount } from "/src/charts";

export const TimeSlider = memo(
  ({
    dates,
    dateRange,
    setDateRange,
    showPostCount = true,
    postCount,
    range = false,
  }) => {
    const marks = useMemo(() => dates.map((date) => date.format), [dates]);
    const onChange = useCallback((ev) => {
      if(!range && Array.isArray(ev)){
        setDateRange(ev[0]);
      }
      else{
        setDateRange(ev);
      }
    }, [setDateRange]);

    return (
      <div
        style={{
          marginTop: "20px",
          marginLeft: "35px",
          marginRight: "35px",
          marginBottom: "20px",
        }}
      >
        {showPostCount && postCount && postCount.lengtht && (
          <div
            style={{
              height: "100px",
              marginBottom: "-5px",
              color: "black",
            }}
          >
            <PostCount data={postCount.slice(0, dates.length - 1)} />
          </div>
        )}
        <Slider
          className={dateRange.isRange ? "RangeSlider" : ""}
          range={dateRange.isRange}
          dots
          marks={marks}
          step={1}
          value={dateRange.value}
          min={0}
          max={marks.length - 1}
          onChange={onChange}
        />
      </div>
    );
  }
);
