import React from "react";
import {createRoot} from 'react-dom/client';
import { DataLoader, ErrorBoundary } from "/src/utils";
import "./styles/index.scss";
import {HashtagExplorer} from "/src/extra";
import {Dashboard} from "/src/Dashboard";


const getPropsFromNode = (node) =>
  Object.fromEntries(
    Array.from(node.attributes)
      .filter(({ name, value }) => name.startsWith("data-"))
      .map(({ name, value }) => [name.substring(5), value])
  );


const hashtagExplorer = document.getElementById("hashtag_explorer");

if (hashtagExplorer) {
  const props = getPropsFromNode(hashtagExplorer);

  const hashtagExplorer_root = createRoot(hashtagExplorer);
  hashtagExplorer_root.render(
    <div className="bag_dashi">
      <ErrorBoundary>
        <DataLoader {...props} fileList={["hashtag_explorer.json", "translations.json"]}>
          <HashtagExplorer/>
        </DataLoader>
      </ErrorBoundary>
    </div>
  );
}


const smartphone = document.getElementById("smartphone");

if (smartphone) {
  const props = getPropsFromNode(smartphone);

  const smartphone_root = createRoot(smartphone)
  smartphone_root.render(
    <div className="bag_dashi">
      <ErrorBoundary>
        <DataLoader {...props}>
          <Dashboard
            hideNodeSelection={props.hidenodeselection}
            moveNodeSelectionToBottom={props.movenodeselectiontobottom}
          />
        </DataLoader>
      </ErrorBoundary>
    </div>
  );
}

for(let i=0;i<10;i++){
  const name = i === 0 ? "bag_network_only" : `bag_network_only${i}`;
  const bag_network_only = document.getElementById(name);

  if (bag_network_only) {
    const props = getPropsFromNode(bag_network_only);
    const bag_network_only_root = createRoot(bag_network_only);

    bag_network_only_root.render(
      <div className="bag_dashi">
        <ErrorBoundary>
          <DataLoader {...props}>
            <Dashboard
              networkOnly={true}
              hideTimeSlider={props.hidetimeslider}
            />
          </DataLoader>
        </ErrorBoundary>
      </div>
    );
  }
}
