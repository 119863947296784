import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export const FilterControl = ({ children, onClick, style, className }) => {
  const htmlProps = {
    style,
    className: `react-sigma-control ${className || ""}`,
  };

  return (
    <div {...htmlProps}>
      <button onClick={onClick} title="Filter anweden">
        {children ? children[0] : <FontAwesomeIcon icon={faFilter} />}
      </button>
    </div>
  );
};
