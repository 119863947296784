const TEXT_COLOR = "#000000";

/**
 * This function draw in the input canvas 2D context a rectangle.
 * It only deals with tracing the path, and does not fill or stroke.
 */
export function drawRoundRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

const max = (arry) => arry.reduce((a, v) => (a > v ? a : v), 0);
const checkEmpty = (s) => !s || s.trim().length === 0;

/**
 * Custom hover renderer
 */
export function drawHover(context, data, settings) {
  if (
    checkEmpty(data.label) &&
    checkEmpty(data.subLabel) &&
    checkEmpty(data.clusterLabel)
  )
    return;
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const subLabelSize = size - 2;

  const label = data.label;
  const subLabel = data.tag !== "unknown" ? data.tag : "";
  const clusterLabel = typeof data.clusterLabel === "string" ? data.clusterLabel : null;
  const lineBreak = "\n";
  const lineHeight = 1.1;

  // Then we draw the label background
  context.beginPath();
  context.fillStyle = "#fff";
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = "#000";

  context.font = `${weight} ${size}px ${font}`;
  const labelLines = label.split(lineBreak).map((a) => a.trim());
  const labelWidth = max(
    labelLines.map((line) => context.measureText(line).width),
  );

  context.font = `${weight} ${subLabelSize}px ${font}`;
  const subLabelLines = subLabel
    ? subLabel.split(lineBreak).map((a) => a.trim())
    : [];
  const subLabelWidth = max(
    subLabelLines.map((line) => context.measureText(line).width),
  );

  context.font = `${weight} ${subLabelSize}px ${font}`;
  const clusterLabelLines = clusterLabel
    ? clusterLabel.split(lineBreak).map((a) => a.trim())
    : [];
  const clusterLabelWidth = max(
    clusterLabelLines.map((line) => context.measureText(line).width),
  );

  const textWidth = Math.max(labelWidth, subLabelWidth, clusterLabelWidth);

  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const hLabel = Math.round((labelLines.length * size) / 2 + 4);
  const hSubLabel = Math.round((subLabelLines.length * subLabelSize) / 2 + 9);
  const hClusterLabel = Math.round(
    (clusterLabelLines.length * subLabelSize) / 2 + 9,
  );

  drawRoundRect(
    context,
    x,
    y - hSubLabel - 12,
    w + 6,
    hClusterLabel + hLabel + hSubLabel + 12 * 3,
    5,
  );
  context.closePath();
  context.fill();

  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;

  // And finally we draw the labels
  context.fillStyle = TEXT_COLOR;
  context.font = `${weight} ${size}px ${font}`;
  for (let i = 0; i < labelLines.length; i++) {
    context.fillText(
      labelLines[i],
      data.x + data.size + 3,
      data.y + size / 3 + size * i * lineHeight,
    );
  }

  if (subLabel) {
    context.fillStyle = TEXT_COLOR;
    context.font = `${weight} ${subLabelSize}px ${font}`;

    for (let i = 0; i < subLabelLines.length; i++) {
      context.fillText(
        subLabelLines[i],
        data.x + data.size + 3,
        data.y -
          (2 * size) / 3 -
          2 +
          lineHeight * subLabelSize * i +
          labelLines.length * size * lineHeight,
      );
    }
  }

  context.fillStyle = TEXT_COLOR;
  context.font = `${weight} ${subLabelSize}px ${font}`;

  if (
    (settings.drawClusterLabels == undefined || !!settings.drawClusterLabels) &&
    !!clusterLabel
  ) {
    for (let i = 0; i < clusterLabelLines.length; i++) {
      context.fillText(
        clusterLabelLines[i],
        data.x + data.size + 3,
        data.y +
          size / 3 +
          3 +
          lineHeight * subLabelSize * subLabelLines.length +
          size * labelLines.length * lineHeight +
          subLabelSize * i * lineHeight,
      );
    }
  }
}

/**
 * Custom label renderer
 */
export function drawLabel(context, data, settings) {
  if (!data.label || data.label.trim().length === 0) return;

  const size = settings.labelSize,
    font = settings.labelFont,
    weight = settings.labelWeight;

  context.font = `${weight} ${size}px ${font}`;
  const lines = data.label.split("\n");
  const lengths = lines.map((l) => context.measureText(l.trim()).width);
  const width = Math.max(...lengths);
  const lineHeight = 1.1;

  context.fillStyle = "#ffffffcc";
  context.fillRect(
    data.x + data.size,
    data.y + size / 3 - 15,
    width + size / 3,
    size * lineHeight * lengths.length + size / 3,
  );

  context.fillStyle = "#000";
  for (let i = 0; i < lengths.length; i++) {
    const line = lines[i].trim();
    context.fillText(
      line,
      data.x + data.size + 3,
      data.y + size / 3 + size * lineHeight * i,
    );
  }
}
