import { useRegisterEvents, useSigma } from "@react-sigma/core";
import { useEffect } from "react";

function getMouseLayer() {
  return document.querySelector(".sigma-mouse");
}

const GraphEventsController = ({
  setHoveredChannels,
  children,
  setSelectedChannels,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode({ node }) {
        const name = `channel-${node}`;
        setSelectedChannels((selectedNodes) => ({...selectedNodes, [name]: !selectedNodes[name]}));
      },
      enterNode({ node }) {
        setHoveredChannels({[`channel-${node}`]: true});
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
      },
      leaveNode() {
        setHoveredChannels({});
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
      },
    });
  }, [
    graph,
    registerEvents,
    setHoveredChannels,
    setSelectedChannels,
  ]);

  return <>{children}</>;
};

export default GraphEventsController;
