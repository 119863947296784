import React, { useRef, useEffect, useState } from "react";
import { useSigma } from "@react-sigma/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export function getUniqueKey() {
  return Math.random().toString(36).slice(2);
}

export const SearchControl = ({
  id,
  className,
  style,
  children,
  zoomLevel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sigma = useSigma();
  const [search, setSearch] = useState("");
  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputId, setInputId] = useState("");

  const inputRef = useRef();

  useEffect(() => {
    setInputId(`search-${getUniqueKey()}`);
  }, []);

  useEffect(() => {
    const newValues = [];
    if (!selected && search.length > 1) {
      sigma.getGraph().forEachNode((key, attributes) => {
        if (
          attributes.label &&
          attributes.label.toLowerCase().includes(search.toLowerCase())
        )
          newValues.push({ id: key, label: attributes.label });
      });
    }
    setValues(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    sigma.getGraph().setNodeAttribute(selected, "highlighted", true);
    const nodeDisplayData = sigma.getNodeDisplayData(selected);
    if (nodeDisplayData) {
      sigma.getCamera().animate(
        { ...nodeDisplayData, ratio: zoomLevel },
        {
          duration: 600,
        },
      );
    }

    return () => {
      sigma.getGraph().setNodeAttribute(selected, "highlighted", false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (isOpen) {
      const f = () => setIsOpen(false);
      window.addEventListener("click", f);
      return () => window.removeEventListener("click", f);
    }
  }, [isOpen]);

  const onInputChange = (e) => {
    const searchString = e.target.value;
    const valueItem = values.find(
      (value) => value.label.replace("\n", "") === searchString,
    );
    if (valueItem) {
      setSearch(valueItem.label);
      setValues([]);
      setSelected(valueItem.id);
    } else {
      setSelected(null);
      setSearch(searchString);
    }
  };

  // Common html props for the div
  const isActive = isOpen ? "SearchControl--active" : "";
  const htmlProps = {
    className: `react-sigma-control ${
      className ? className : ""
    } SearchControl ${isActive}`,
    id,
    style,
  };

  return (
    <div {...htmlProps}>
      <button
        onClick={
          !isOpen
            ? (ev) => {
                setIsOpen(true);
                setTimeout(() => inputRef?.current?.focus(), 0);
                ev.stopPropagation();
              }
            : null
        }
        title="Filter anweden"
        style={{ position: "relative" }}
      >
        {children ? children[0] : <FontAwesomeIcon icon={faSearch} />}

        <div
          className={isOpen ? "SearchMenu" : "SearchMenu SearchMenu--hidden"}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="SearchMenu__Search">
            <span
              className="SearchMenu__Icon"
              onClick={() => setIsOpen((open) => false)}
            >
              {children ? children[0] : <FontAwesomeIcon icon={faSearch} />}
            </span>
            <label htmlFor={inputId} style={{ display: "none" }}>
              Search a node
            </label>
            <input
              ref={inputRef}
              id={inputId}
              type="text"
              placeholder="Search..."
              list={`${inputId}-datalist`}
              value={search}
              onChange={onInputChange}
            />
          </div>
          <datalist id={`${inputId}-datalist`}>
            {values.map(({ id, label }) => (
              <option key={id} value={label}>
                {label}
              </option>
            ))}
          </datalist>
        </div>
      </button>
    </div>
  );
};
