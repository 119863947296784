import { useRef, useEffect } from "react";
import { useSigma } from "@react-sigma/core";

export const ZoomToNode = ({
  channelSelection,
  zoomLevel = 0.02,
}) => {
  const curNode = useRef(null);
  const sigma = useSigma();

  useEffect(() => {
    const nodes = Object.entries(channelSelection).filter(([name, value]) => value).map(([name, value]) => name);
    if (nodes.length !== 1) {
      curNode.current = null;
      return;
    }

    const graph = sigma.getGraph();
    const key = nodes[0].substring("channel-".length);

    if (curNode.current !== key) {
      const nodeDisplayData = sigma.getNodeDisplayData(key);
      curNode.current = key;

      if (nodeDisplayData) {
        sigma.getCamera().animate(
          { ...nodeDisplayData, ratio: zoomLevel },
          {
            duration: 600,
          }
        );
        graph.setNodeAttribute(key, "highlighted", true);

        return () => {
          graph.setNodeAttribute(key, "highlighted", false);
        };
      }
    }
  }, [channelSelection, sigma, zoomLevel]);

  return null;
};
