import { useSigma } from "@react-sigma/core";
import { useEffect } from "react";
import {useSettings} from "/src/utils";

import { drawLabel, drawHover } from "../canvas-utils";
import useDebounce from "../use-debounce";

const NODE_FADE_COLOR = "#bbb";
const EDGE_FADE_COLOR = "#eee";

const GraphSettingsController = ({
  children,
  selection,
  hoverSelection,
  filter,
  aliasing,
}) => {
  const settings = useSettings();
  const sigma = useSigma();
  const graph = sigma.getGraph();

  // Here we debounce the value to avoid having too much highlights refresh when
  // moving the mouse over the graph:
  const debouncedHoverSelection = useDebounce(hoverSelection, 50);
  const debouncedSelection = useDebounce(selection, 50);

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  // TODO is this necessairy what does it do?
  useEffect(() => {
    if(settings.sigmaSettings){
      Object.entries(settings.sigmaSettings).forEach(([name, value]) => {
        sigma.setSetting(name, value);
      });
    }

    sigma.setSetting("hoverRenderer", (context, data, settings) =>
      drawHover(
        context,
        { ...sigma.getNodeDisplayData(data.key), ...data },
        settings
      )
    );
  }, [sigma, graph, settings.sigmaSettings]);

  /**
   * Update node and edge reducers when a node is hovered, to highlight its
   * neighborhood:
   */
  useEffect(() => {
    // TODO change the channel ids!
    const cutof = "channel-".length;
    const hoveredNodes = new Set(Object.entries(debouncedHoverSelection).filter(([name, value]) => value).map(([name, value]) => name.substring(cutof)));
    const selection = new Set(Object.entries(debouncedSelection).filter(([name, value]) => value).map(([name, value]) => name.substring(cutof)));

    sigma.setSetting(
      "nodeReducer",
      (hoveredNodes.size > 0 || filter)
      ? (node, data) => 
          hoveredNodes.has(node) || (filter && selection.has(node))
            ? { ...data, zIndex: 1 }
            : {
                ...data,
                zIndex: 0,
                label: "",
                color: NODE_FADE_COLOR,
                image: null,
                highlighted: false,
              }
      : null
    );

    // This is expansive as fuck
    sigma.setSetting(
      "edgeReducer",
      (hoveredNodes.size > 0 || filter)
      ? (edge, data) => {
        // TODO maybe make this directional
        const source = graph.source(edge);
        const target = graph.source(edge);

        return (hoveredNodes.has(source) || hoveredNodes.has(target))
          ? {
            ...data,
            color: graph.getTargetAttributes(edge)?.color || hoveredColor,
          }
          : (filter && (selection.has(source) || selection.has(target)))
            ? data
            : { ...data, color: EDGE_FADE_COLOR, hidden: true }
      }
      : null
    );
  }, [debouncedHoverSelection, debouncedSelection, filter, graph, sigma]);

  return <>{children}</>;
};

export default GraphSettingsController;
