import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  createElement,
  memo,
} from "react";
import { ArcsLayer } from "@nivo/arcs";
import { ResponsivePie } from "@nivo/pie";
import { useTooltip, BasicTooltip } from "@nivo/tooltip";
import { palette } from "/src/configs/nordsonne";
import {useTimedMemo} from "/src/utils";

const PieTooltip = ({ datum }) => (
  <BasicTooltip
    id={datum.id}
    value={datum.formattedValue}
    enableChip={true}
    color={datum.color}
  />
);

function Arcs({
  arcGenerator,
  centerX,
  centerY,
  dataWithArc,
  onClick,
  selectedTopics,
  hoveredTopic,
  setHoveredTopic,
  ...props
}) {
  const tooltip = PieTooltip;
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const ref = useRef(null);

  const data = useTimedMemo("arcs_data",
    () =>
      dataWithArc.map((datum) => ({
        ...datum,
        arc: {
          ...datum.arc,
          outerRadius:
            datum.arc.outerRadius *
            (1 +
              (selectedTopics[datum.id] ? 0.1 : 0) +
              (hoveredTopic === datum.id ? 0.1 : 0)),
        },
      })),
    [dataWithArc, selectedTopics, hoveredTopic]
  );

  return (
    <g ref={ref}>
      <ArcsLayer
        center={[centerX, centerY]}
        data={data}
        arcGenerator={arcGenerator}
        borderWidth={0}
        borderColor="transparent"
        transitionMode="innerRadius"
        onClick={onClick}
        onMouseEnter={(datum, event) => {
          showTooltipFromEvent(createElement(tooltip, { datum }), event);
          setHoveredTopic(datum.id);
        }}
        onMouseMove={(datum, event) => {
          showTooltipFromEvent(createElement(tooltip, { datum }), event);
          setHoveredTopic(datum.id);
        }}
        onMouseLeave={(datum, event) => {
          hideTooltip();
          setHoveredTopic(null);
        }}
      />
    </g>
  );
}

export const Topics = ({
    name,
    data,
    limit = 10,
    selectedTopics = {},
    setSelectedTopics,
    topics: allTopics,
    format = "count",
    ...props
  }) => {
    const topics = useTimedMemo("topics_topics", () => {
      const total = allTopics.reduce((a, [key, value]) => a + value, 0);

      return allTopics
        .sort(([keyA, valueA], [keyB, valueB]) => valueB - valueA)
        .slice(0, limit)
        .map(([key, value]) => ({
          id: key,
          label: key,
          value: format === "percent" ? value / total : value,
        }));
    }, [allTopics, limit, format]);
    const [hoveredTopic, setHoveredTopic] = useState(null);

    const onClick = useCallback(
      (node) =>
        setSelectedTopics((selectedTopics) => ({
          ...selectedTopics,
          [node.id]: !selectedTopics[node.id],
        })),
      [setSelectedTopics]
    );

    const ArcsComponent = useCallback(
      (props) => (
        <Arcs
          {...{
            ...props,
            onClick,
            selectedTopics,
            hoveredTopic,
            setHoveredTopic,
          }}
        />
      ),
      [onClick, selectedTopics, hoveredTopic]
    );

    const clickHandler = useCallback(
      (node, event) => {
        if (node.id !== "Sonstige") {
          setSelectedTopics((selectedTopics) => ({
            ...selectedTopics,
            [node.id]: !selectedTopics[node.id],
          }));
        }
      },
      [setSelectedTopics]
    );

    if (topics.length === 0) {
      return null;
    }

    return (
      <div style={{ width: "100%", height: "500px", color: "black" }}>
        <ResponsivePie
          onClick={clickHandler}
          data={topics}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#ccc"
          arcLinkLabelsThickness={2}
          colors={palette}
          valueFormat={format === "percent" ? ">-.2%" : ">d"}
          arcLabelsSkipAngle={10}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          layers={["arcLinkLabels", ArcsComponent, "legends"]}
        />
      </div>
    );
  };
