import React, { useMemo, useState, useEffect } from "react";
import {useTimedMemo, timerWrap, timerHoc} from "/src/utils";
import { AutoForceLayout } from "./AutoForceLayout";
import { ZoomToNode } from "./ZoomToNode";
import { FilterControl } from "./FilterControl";
import { SearchControl } from "./SearchControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faCompress,
  faSearchMinus,
  faDotCircle,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { colors, palette } from "/src/configs/nordsonne";
import {
  SigmaContainer,
  ControlsContainer,
  ZoomControl,
  FullScreenControl,
} from "@react-sigma/core";
import { useSettings } from "/src/utils";
import {shadeColor} from "../utils";

import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
import {drawLabel, drawHover} from "../canvas-utils";
import { useIdeologys, useNetwork, mathMax } from "/src/utils";

import "@react-sigma/core/lib/react-sigma.min.css";

const _onErrorFalse = (f) => {
  try {
    return f();
  } catch (error) {
    console.log(error);
  }
  return false;
};
const _selectCorrectDate = (obj, level) => {
  // TODO this removes the day from the date
  const levelShort = level.substring(0, level.length - 3);
  const data = obj[level] || obj[levelShort];

  if (data === undefined) {
    if (Object.keys(obj).length === 0) {
      throw new Error(
        `"network.json" contains no information for level \"${level}\" or \"${levelShort}\"`
      );
    }

    const keys = Object.keys(obj)
      .filter((key) => key !== "all")
      .sort();
    const [start, end] = [keys[0], keys[keys.length - 1]];

    throw new Error(
      `Can't select date \"${level}\" in network.json, check your settings.json for correct \"startDate='${start}'\" and \"endDate='${end}'\"`
    );
  }
  return data;
};

const mirkoAliasing = (settings, size, color) => {
    const thickness = size || 1;
    const antialiasing = settings.mirkoAliasingEnabled
      ? Math.max(
          0,
          Math.min(
            settings.mirkoAliasingMaxDarken,
            (1 - thickness) * settings.mirkoAliasingFactor
          )
        )
      : 0;

    return settings.mirkoAliasingEnabled
          ? shadeColor(color, antialiasing)
          : color
    ;
}

// TODO can we optimize this
// And move most of this code into the state machine?
// Also this needs to fire on date change so better cache it in general
// And it should be supplied to root directly
export const useGraph = ({
  level,
  sizeMultiplier,
  sizeMinimum,
  edgeSizeMultiplier,
  edgeSizeMinimum,
  resizeNodes,
  nodeSizeAlgorithm,
  ...settings
}) => {
  const predefinedIdeologys = useIdeologys();
  const { nodes: allNodes, edges: allEdges, prerendered } = useNetwork();

  const data = useTimedMemo("uG_data", () => {
    if (!allNodes || !Object.keys(allNodes).length) {
      return;
    }

    const nodes = _selectCorrectDate(allNodes, level);
    const edges = _selectCorrectDate(allEdges, level);

    let inDegree = {};
    edges.forEach(([keyA, keyB, data]) => {
      inDegree[keyA] = data.weight + (inDegree[keyA] ?? 0);
    });

    let outDegree = {};
    edges.forEach(([keyA, keyB, data]) => {
      outDegree[keyB] = 1 + (outDegree[keyB] ?? 0);
    });

    const maxInDegree = mathMax(Object.values(inDegree));
    const maxOutDegree = mathMax(Object.values(outDegree));
    const maxWeight = mathMax(edges.map(([keyA, keyB, { weight }]) => weight));

    let ideologys = new Set();
    Object.entries(nodes).forEach(([key, data]) => {
      ideologys.add(data["Ideologie"]);
    });
    ideologys = Object.fromEntries(
      Array.from(ideologys).map((ideology, i) => [
        ideology,
        {
          clusterLabel: ideology,
          color: predefinedIdeologys[ideology ? ideology : "FALLBACK"] || palette[i % palette.length],
        },
      ])
    );

    let graph = {};
    graph.edges = edges.map(([keyA, keyB, attributes]) => [
      keyA,
      keyB,
      {
        ...attributes,
        color: mirkoAliasing(settings, (
            edgeSizeMinimum +
            (edgeSizeMultiplier * attributes.weight) / maxWeight
          ), colors.gray.basic),
        size:
          edgeSizeMinimum +
          (edgeSizeMultiplier * attributes.weight) / maxWeight,
      },
    ]);

    const maxNodeSize = Object.values(nodes).reduce(
      (a, node) => Math.max(a, node.size),
      0
    );

    graph.nodes = Object.entries(nodes).map(([key, node]) => ({
      key: key,
      color: colors.gray.basic,
      ...ideologys[node["Ideologie"]],
      label: node.name !== undefined ? node.name : key,
      ideology: node.Ideologie,
      x: 1000 * Math.random(),
      y: 1000 * Math.random(),
      preSize: (inDegree[key] || 0) / maxOutDegree,
      ...node,
      size:
        node.size !== undefined
          ? resizeNodes
            ? sizeMinimum + (sizeMultiplier * node.size) / maxNodeSize
            : node.size * sizeMultiplier || sizeMinimum
          : nodeSizeAlgorithm === "in" || nodeSizeAlgorithm === undefined
          ? sizeMinimum + (sizeMultiplier * (inDegree[key] || 0)) / maxInDegree
          : nodeSizeAlgorithm === "out"
          ? sizeMinimum +
            (sizeMultiplier * (outDegree[key] || 0)) / maxOutDegree
          : nodeSizeAlgorithm === "inout"
          ? sizeMinimum +
            (sizeMultiplier * (inDegree[key] + outDegree[key] || 0)) /
              (maxInDegree + maxOutDegree)
          : 1,
    }));

    graph.ideologys = ideologys;
    graph.prerendered = prerendered;

    return graph;
  }, [
    allNodes,
    allEdges,
    level,
    sizeMultiplier,
    predefinedIdeologys,
    prerendered,
    sizeMinimum,
    edgeSizeMinimum,
    edgeSizeMultiplier,
  ]);
  return data;
};

const Root = timerHoc("Root", ({
  date = "",
  sizeMinimum = 2,
  sizeMultiplier = 10,
  zoomLevel = 0.02,
  edgeSizeMultiplier,
  edgeSizeMinimum,
  allNodes,
  selectedNodes,
  setSelectedNodes,
  selectedGroups,
  setSelectedGroups,
  setHoveredNode,
  hoveredNode,
  hoveredGroup,
  forceLayout,
  resizeNodes = true,
  drawClusterLabels = true,
  nodeSizeAlgorithm = "in",
  mirkoAliasingFactor = 5,
  mirkoAliasingMaxDarken = 5,
  mirkoAliasingEnabled = true,

  selection,
  hoverSelection,
  channelSelection,
  setHoveredChannels,
  setSelectedChannels,
}) => {
  const dataset = useGraph({
    level: date,
    sizeMultiplier,
    drawClusterLabels,
    sizeMinimum,
    edgeSizeMultiplier,
    edgeSizeMinimum,
    resizeNodes,
    nodeSizeAlgorithm,
    mirkoAliasingFactor,
    mirkoAliasingMaxDarken,
    mirkoAliasingEnabled,
  });

  const [filtersState, setFiltersState] = useState(false);
  const settings = useSettings();

  if (!dataset) return null;

  return (
    <SigmaContainer
      settings={{
        defaultDrawNodeLabel: drawLabel,
        defaultDrawNodeHover: drawHover,
        defaultEdgeType: "arrow",
        labelDensity: 0.07,
        labelGridCellSize: 60,
        labelRenderedSizeThreshold: 15,
        labelFont: "Bould, sans-serif",
        zIndex: true,
        mirkoAliasingFactor,
        mirkoAliasingMaxDarken,
        mirkoAliasingEnabled,
      }}
      className="react-sigma"
    >
      <GraphSettingsController
        hoveredNode={hoveredNode}
        hoveredGroup={hoveredGroup}
        hoverSelection={hoverSelection}
        selection={selection}
        filter={filtersState}
        aliasing={(size, color) => mirkoAliasing(settings, size, color)}
      />
      <GraphEventsController
        setHoveredChannels={setHoveredChannels}
        setSelectedChannels={setSelectedChannels}
      />
      <ZoomToNode
        channelSelection={channelSelection}
        zoomLevel={zoomLevel}
      />
      {/* TODO This stuff annoys me why rerender why the second part why even */}
      <GraphDataController
        dataset={dataset}
      />
      <AutoForceLayout
        settings={forceLayout}
        prerendered={_onErrorFalse(() =>
          _selectCorrectDate(dataset.prerendered, date)
        )}
        dataset={date}
      />
      <ControlsContainer position={"top-left"}>
        <FullScreenControl
          children={[
            <FontAwesomeIcon icon={faExpand} key="expand" />,
            <FontAwesomeIcon icon={faCompress} key="compress" />,
          ]}
        />
        <ZoomControl
          children={[
            <FontAwesomeIcon icon={faSearchPlus} key="plus" />,
            <FontAwesomeIcon icon={faSearchMinus} key="minus" />,
            <FontAwesomeIcon icon={faDotCircle} key="center" />,
          ]}
        />
        <FilterControl
          onClick={() => setFiltersState((v) => !v)}
          className={
            filtersState ? "GraphFilter--active GraphFilter" : "GraphFilter"
          }
        />
        <SearchControl zoomLevel={zoomLevel} />
      </ControlsContainer>
    </SigmaContainer>
  );
});

export default Root;
