import React, { useMemo } from "react";
import { ResponsiveStream } from "@nivo/stream";
import { palette } from "/src/configs/nordsonne";
import {useTimedMemo} from "/src/utils";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

import { useCallback, createElement } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useAnimatedPath, useMotionConfig } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";
import { BasicTooltip } from "@nivo/tooltip";

const LayerTooltip = ({ layer }) => (
  <BasicTooltip id={layer.label} enableChip={true} color={layer.color} />
);

export const StreamLayers = ({ layers, onClick, ...props }) => {
  return (
    <g>
      {layers.map((layer, i) => (
        <StreamLayer
          key={i}
          layer={layer}
          getBorderColor={() => "black"}
          borderWidth={0}
          fillOpacity={1}
          isInteractive={true}
          tooltip={LayerTooltip}
          onClick={(ev) => onClick(layer.id)}
        />
      ))}
    </g>
  );
};

export const StreamLayer = ({
  layer,
  fillOpacity,
  borderWidth,
  getBorderColor,
  isInteractive,
  tooltip,
  onClick,
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const handleMouseHover = useCallback(
    (event) => {
      showTooltipFromEvent(createElement(tooltip, { layer }), event, "left");
    },
    [showTooltipFromEvent, layer, tooltip]
  );

  const { animate, config: springConfig } = useMotionConfig();
  const animatedPath = useAnimatedPath(layer.path);
  const animatedProps = useSpring({
    color: layer.color,
    config: springConfig,
    immediate: !animate,
  });

  return (
    <animated.path
      d={animatedPath}
      fill={layer.fill ? layer.fill : animatedProps.color}
      fillOpacity={fillOpacity}
      stroke={getBorderColor(layer)}
      strokeWidth={borderWidth}
      onMouseMove={isInteractive ? handleMouseHover : undefined}
      onMouseEnter={isInteractive ? handleMouseHover : undefined}
      onMouseLeave={isInteractive ? hideTooltip : undefined}
      onClick={onClick}
    />
  );
};

export const TagStream = ({
  data: monthBased,
  tag,
  limit = 10,
  setSelectedTopics,
  topicOrder,
  format = "count",
}) => {
  const data = useTimedMemo("TagStream_data", () => {
    const monthBasedTotal = Object.fromEntries(
      Object.entries(monthBased).map(([key, value]) => [
        key,
        Object.values(value).reduce((a, v) => a + v, 0),
      ])
    );
    return Object.keys(monthBased)
      .sort()
      .map((date) =>
        topicOrder.reduce(
          (a, key) => ({
            ...a,
            [key]:
              format === "percent"
                ? monthBased[date][key] / monthBasedTotal[date] || 0
                : monthBased[date][key] || 0,
          }),
          {}
        )
      );
  }, [monthBased, topicOrder, format]);

  const keys = useTimedMemo("TagStream_keys", () => {
    const tagsInTopicOrder = tag.filter((x) => topicOrder.includes(x));
    return tagsInTopicOrder.length ? tagsInTopicOrder : topicOrder;
  }, [tag, topicOrder]);

  return (
    <ResponsiveStream
      data={Object.values(data)}
      keys={keys}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: 36,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -40,
      }}
      enableGridY={false}
      enableGridX={true}
      curve="basis"
      offsetType="silhouette"
      colors={palette}
      fillOpacity={0.85}
      borderColor={{ theme: "background" }}
      textColor="black"
      valueFormat={format === "percent" ? ">-#.1%" : ">d"}
      layers={[
        "grid",
        "axes",
        (props) => (
          <StreamLayers
            showTooltips={false}
            {...props}
            onClick={(layerId) =>
              setSelectedTopics((topics) => ({
                ...topics,
                [layerId]: !topics[layerId],
              }))
            }
          />
        ),
        "slices",
      ]}
    />
  );
};
