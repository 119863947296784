import { useEffect } from "react";
import { useSigma } from "@react-sigma/core";
import { useWorkerLayoutForceAtlas2 } from "@react-sigma/layout-forceatlas2";
import { useCamera } from "@react-sigma/core";
import { useSettings } from "/src/utils";

const defaultAutoRunFor = 1000;

const exportRender = (sigma, graph, download) => {
  const data = Object.fromEntries(
    graph.nodes().map((node) => {
      const displayData = sigma.getNodeDisplayData(node);
      return [node, [displayData.x, displayData.y]];
    })
  );

  if (download) {
    // file download
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = "positions.json";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export const AutoForceLayout = ({ prerendered, dataset, settings }) => {
  const { start, stop, isRunning } = useWorkerLayoutForceAtlas2({
    settings,
    weighted: true,
  });
  const { reset } = useCamera();
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const extraSettings = useSettings();

  useEffect(() => {
    if (prerendered) {
      return;
    }
    if (isRunning) {
      console.warn(
        "AutoForceLayout already running! This can't happen as the cleanup effect wasn't called then."
      );
      return;
    }

    start();
    const timeout = window.setTimeout(() => {
      stop();
      reset();
      exportRender(
        sigma,
        graph,
        extraSettings?.downloadAutoForceLayoutPositions
      );
    }, extraSettings?.forceLayout?.autoRunFor ?? defaultAutoRunFor);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, prerendered, settings]);
  return null;
};
