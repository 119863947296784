import React from "react";
import { Translation } from "/src/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const DashboardCrash = () => (
  <div className="DataLoader">
    <div className="DataLoader__Text">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <Translation k="base.dashboardCrash" />
    </div>
  </div>
);
