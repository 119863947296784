import { useEffect } from "react";

export const getFirstTrue = (o) => {
  const l = Object.entries(o).filter(([name, value]) => !!value);

  if (l.length > 0) {
    return l[0][0];
  }
  return null;
};

export const getAccumulatedTopicData = ({
  channelData,
  channels,
  startDate = null,
  endDate = null,
  key = "Persons",
}) => {
  channels = channels || Object.keys(channelData);

  let tags = {};
  for (let channel of channels) {
    const cData = channelData[channel];

    if (!cData) {
      continue;
    }

    const data = cData[key];

    for (let date in data) {
      const dateData = data[date];

      // Clean data
      if (Array.isArray(dateData)) {
        continue;
      }

      // Check for time intervall
      const longDate = date + "-01";
      if (
        (!!startDate && startDate > longDate) ||
        (!!endDate && endDate < longDate)
      ) {
        continue;
      }

      for (let keyword in dateData) {
        const value = dateData[keyword];
        if (!tags[date]) {
          tags[date] = {};
        }
        tags[date][keyword] = value + (tags[date][keyword] || 0);
      }
    }
  }

  return tags;
};

export const getAccumulatedTopics = ({
  channelData,
  channels,
  key = "Persons",
  startDate,
  endDate,
}) => {
  channels = channels || Object.keys(channelData);

  let tags = {};
  for (let channel of channels) {
    const cData = channelData[channel];
    if (!cData) {
      continue;
    }

    const data = cData[key];

    for (let date in data) {
      const dateData = data[date];

      // Clean data
      if (Array.isArray(dateData)) {
        continue;
      }

      // Check for time intervall
      //const longDate = date + "-01";
      const longDate = date;
      if (
        (!!startDate && startDate > longDate) ||
        (!!endDate && endDate < longDate)
      ) {
        continue;
      }

      for (let keyword in dateData) {
        const value = dateData[keyword];
        tags[keyword] = value + (tags[keyword] || 0);
      }
    }
  }

  return tags;
};

export const getAccumulatedPostCount = ({
  channelData,
  channels,
  startDate,
  endDate,
  key = "counts",
  _months,
}) => {
  channels = channels || Object.keys(channelData);

  let dataDict = _months
    ? _months.reduce((a, date) => ({ ...a, [date]: 0 }), {})
    : {};
  for (let channel of channels) {
    const cData = channelData[channel];
    if (!cData) {
      continue;
    }

    const data = cData["Posts_per_month"];

    for (let date in data) {
      const dateData = data[date];

      // Clean data
      if (Array.isArray(dateData)) {
        continue;
      }

      // Check for time intervall
      const longDate = date + "-01";
      if (
        (!!startDate && startDate > longDate) ||
        (!!endDate && endDate <= longDate)
      ) {
        continue;
      }
      dataDict[date] = dataDict[date] + dateData[key] || dateData[key];
    }
  }

  return Object.keys(dataDict)
    .sort()
    .map((key) => dataDict[key]);
};

export const getOnlyTrue = (obj) =>
  Object.entries(obj)
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

export const toggleArrayValues = (arry, obj) => {
  const index = arry.indexOf(obj);
  if (index >= 0) {
    return [...arry.slice(0, index), ...arry.slice(index + 1)];
  }
  return [...arry, obj];
};

export const getChannelGroups = (channel) => {
  const ideology = channel["Ideologie"];
  const ideologyRough = channel["Ober_Ideologie"];
  const language = channel["first_language"];

  let groups = []
  if (ideology && ideology !== "??" && ideology !== "---") {
    groups.push(`ideology-${ideology}`);
  }

  if (ideologyRough && ideologyRough !== "??" && ideologyRough !== "---") {
    groups.push(`organisation-${ideologyRough}`);
  }

  if (channel["Chat"]) {
    groups.push("broadcast");
  } else {
    groups.push("megagroup");
  }

  if (language && language !== "None") {
    groups.push(`language-${language}`);
  }

  // TODO maybe store this in channels aswell?
  return groups;
}

export const getAccumulatedByTypes = (channelData, ideologys = {}) => {
  let dataByIdeologys = {};
  let dataByIdeologysRough = {};
  let dataByChatType = { megagroup: [], broadcast: [] };
  let dataByLanguage = {};
  let dataByAnonymization = { yes: [], no: [] };
  const regexAnonymized = /^x_\d+$/;

  for (let key in channelData) {
    const value = channelData[key];
    const ideology = value["Ideologie"];
    const ideologyRough = value["Ober_Ideologie"];
    const language = value["first_language"];
    const isAnonymized = !!key.match(regexAnonymized) ? "yes" : "no";

    dataByAnonymization[isAnonymized].push(key);

    if (ideology && ideology !== "??" && ideology !== "---") {
      if (dataByIdeologys[ideology]) {
        dataByIdeologys[ideology].push(key);
      } else {
        dataByIdeologys[ideology] = [key];
      }
    } else if (false) {
      if (dataByIdeologys[null]) {
        dataByIdeologys[null].push(key);
      } else {
        dataByIdeologys[null] = [key];
      }
    }

    if (ideologyRough && ideologyRough !== "??" && ideologyRough !== "---") {
      if (dataByIdeologysRough[ideologyRough]) {
        dataByIdeologysRough[ideologyRough].push(key);
      } else {
        dataByIdeologysRough[ideologyRough] = [key];
      }
    } else if (false) {
      if (dataByIdeologysRough[null]) {
        dataByIdeologysRough[null].push(key);
      } else {
        dataByIdeologysRough[null] = [key];
      }
    }

    if (value["Chat"]) {
      dataByChatType["megagroup"].push(key);
    } else {
      dataByChatType["broadcast"].push(key);
    }

    if (language && language !== "None") {
      if (dataByLanguage[language]) {
        dataByLanguage[language].push(key);
      } else {
        dataByLanguage[language] = [key];
      }
    }
  }

  return {
    ...Object.fromEntries(
      Object.entries(dataByLanguage).map(([key, value]) => [
        "language-" + key,
        {
          id: "language-" + key,
          name: key,
          members: value,
        },
      ])
    ),
    ...Object.fromEntries(
      Object.entries(dataByIdeologys).map(([key, value]) => [
        "ideology-" + key,
        {
          id: "ideology-" + key,
          name: key,
          color: ideologys[key],
          members: value,
        },
      ])
    ),
    ...Object.fromEntries(
      Object.entries(dataByIdeologysRough).map(([key, value]) => [
        "organisation-" + key,
        {
          id: "organisation-" + key,
          name: key,
          members: value,
        },
      ])
    ),
    megagroup: {
      id: "megagroup",
      name: "Gruppen Chat",
      members: dataByChatType["megagroup"],
    },
    broadcast: {
      id: "broadcast",
      name: "Channel",
      members: dataByChatType["broadcast"],
    },
  };
};

export const mathMax = (arr) => {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
};

export const useChanged = (name, check) => {
  useEffect(() => console.log("Changed", name), [check, name]);
};
