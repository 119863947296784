import { useSigma } from "@react-sigma/core";
import { useEffect } from "react";

const GraphDataController = ({
  dataset,
  children,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  // TODO why use an effect and not pass this directly?
  useEffect(() => {
    if (!graph || !dataset) return;

    const { ideologys } = dataset;

    // TODO in the old version I implemented the filter here!
    dataset.nodes.forEach((node) =>
      graph.addNode(node.key, {
        ...ideologys[node["Ideologie"]],
        ...node,
      })
    );
    dataset.edges.forEach(([source, target, attributes]) => {
      graph.addEdge(source, target, attributes);
    });

    return () => graph.clear();
  }, [graph, dataset]);

  return <>{children}</>;
};

export default GraphDataController;
