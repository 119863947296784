const translations = {
  postCount: "Anzahl Posts/Nachrichten",
  "dashboard.selectedNodes": "Ausgewählte Kanäle",
  "dashboard.groups": "Gruppen",
  "dashboard.timeLine": "Zeitraum",
  "dashboard.topicTagStream": "Zeitverlauf",
  "dashboard.actors": "Akteure",
  "dashboard.fullTimeOverview": "Gesamten Zeitraum",
  "dashboard.networkTitle": "Netzwerk",
  "dashboard.allButton": "Alle/Keine",
  "topicCategory-Themen": "Themen",
  "topicCategory-Personen": "Personen",
  "topicCategory-Orte": "Orte",
  "topicCategory-Medientyp": "Medientyp",
  "topicCategory-Domains": "Domains",
  "base.dashboardCrash": "Dashboard abgestürzt",
  "base.loading": "Dashboard lädt Daten...",
};

export default translations;
