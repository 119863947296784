import {useTimedMemo} from "/src/utils";
import React, {
  memo,
  useState,
  useCallback,
  useRef,
  useMemo,
  createElement,
} from "react";
import { useTopics } from "/src/utils";
import { ArcsLayer } from "@nivo/arcs";
import { ResponsivePie } from "@nivo/pie";
import { useTooltip, BasicTooltip } from "@nivo/tooltip";
import { colors } from "/src/configs/nordsonne";

const Circle = ({
  labels,
  label,
  centerX,
  centerY,
  innerRadius,
  onClick,
  color,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <g onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)}>
        <circle
          className="Pointer Brighten"
          cx={centerX}
          cy={centerY}
          r={innerRadius - 3}
          onClick={onClick}
          fill={color ? color : hover ? colors.gray.lighter : colors.gray.basic}
        />
        {labels?.length > 0 ? (
          <>
            <text
              className="NoPointer"
              x={centerX}
              y={centerY}
              textAnchor="middle"
              dy="-.7em"
              onClick={onClick}
            >
              {labels[0]}
            </text>
            <text
              className="NoPointer"
              x={centerX}
              y={centerY}
              textAnchor="middle"
              dy=".7em"
              onClick={onClick}
            >
              {labels[1]}
            </text>
          </>
        ) : (
          <text
            className="NoPointer"
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dy=".2em"
            onClick={onClick}
          >
            {label}
          </text>
        )}
      </g>
    </>
  );
};

const PieTooltip = ({ datum }) => (
  <BasicTooltip
    id={datum.id}
    value={datum.formattedValue}
    enableChip={true}
    color={datum.color}
  />
);

function Arcs({
  arcGenerator,
  centerX,
  centerY,
  dataWithArc,
  onClick,
  selectedTopics = [],
  hoveredTopic,
  setHoveredTopic,
  ...props
}) {
  const tooltip = PieTooltip;
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const ref = useRef(null);

  const data = useTimedMemo("arcs_data",
    () =>
      dataWithArc.map((datum) => ({
        ...datum,
        arc: {
          ...datum.arc,
          outerRadius:
            datum.arc.outerRadius *
            (1 +
              (selectedTopics[datum.id] ? 0.1 : 0) +
              (hoveredTopic === datum.id ? 0.1 : 0)),
        },
      })),
    [dataWithArc, selectedTopics, hoveredTopic]
  );

  return (
    <g ref={ref}>
      <ArcsLayer
        center={[centerX, centerY]}
        data={data}
        arcGenerator={arcGenerator}
        borderWidth={0}
        borderColor="transparent"
        transitionMode="innerRadius"
        onClick={onClick}
        onMouseEnter={(datum, event) => {
          showTooltipFromEvent(createElement(tooltip, { datum }), event);
          setHoveredTopic(datum.id);
        }}
        onMouseMove={(datum, event) => {
          showTooltipFromEvent(createElement(tooltip, { datum }), event);
          setHoveredTopic(datum.id);
        }}
        onMouseLeave={(datum, event) => {
          hideTooltip();
          setHoveredTopic(null);
        }}
      />
    </g>
  );
}

export const useClickTopics = ({ topics: allTopics, level, setLevel }) => {
  const topicMetaData = useTopics();
  const total = useTimedMemo("ct_total",
    () => allTopics.reduce((a, [k, v]) => a + v, 0),
    [allTopics]
  );

  const topics = useTimedMemo("ct_topics", () => {
    const lvl3 = Object.fromEntries(
      allTopics
        .map(([key, value]) => [
          key,
          {
            name: topicMetaData[key].LV3,
            id: topicMetaData[key].LV3,
            label: topicMetaData[key].LV3,
            value: value / total,
            ...topicMetaData[key],
          },
        ])
        .sort((a, b) =>
          a["Order1"] < b["Order1"]
            ? -1
            : a["Order1"] > b["Order1"]
            ? 1
            : a["Order2"] - b["Order2"]
        )
    );

    let lvl2 = {};
    for (let v of Object.values(lvl3)) {
      const k = v["LV2"];
      lvl2[k] = lvl2[k] ? [...lvl2[k], v] : [v];
    }
    let lvl1 = {};
    for (let [k2, v] of Object.entries(lvl2)) {
      const k = v[0]["LV1"];
      const v2 = {
        name: k2,
        id: k2,
        label: k2,
        children: v,
        value: v.reduce((a, v2) => a + v2.value, 0),
        color: v[0]?.color,
        color2: v[0]?.color2,
        color3: v[0]?.color3,
        Order2: v[0]?.Order2,
        Order1: v[0]?.Order1,
      };
      lvl1[k] = lvl1[k] ? [...lvl1[k], v2] : [v2];
    }

    const data = {
      name: "nivo",
      children: Object.entries(lvl1)
        .map(([key, value]) => ({
          name: key,
          id: key,
          label: key,
          children: value.sort((a, b) => a["Order2"] - b["Order2"]),
          value: value.reduce((a, v2) => a + v2.value, 0),
          color: value[0]?.color,
          color2: value[0]?.color2,
          color3: value[0]?.color3,
          Order1: value[0]?.Order1,
        }))
        .sort((a, b) => a["Order1"] - b["Order1"]),
    };
    return data;
  }, [allTopics, topicMetaData, total]);

  //const [level, setLevel] = useState([]);

  let cdata = [];

  if (level.length >= 0) {
    cdata = topics.children;
  }

  if (level.length >= 1) {
    let new_cdata = cdata?.find((child) => child.id === level[0]);
    if (!new_cdata || !new_cdata.children) {
      level = [];
    } else {
      cdata = new_cdata.children;
    }
  }
  if (level.length >= 2) {
    let new_cdata = cdata?.find((child) => child.id === level[1]);
    if (!new_cdata || !new_cdata.children) {
      level = [level[0]];
    } else {
      cdata = new_cdata.children;
    }
  }

  return {
    level,
    setLevel,
    cdata,
    topics,
    topicMetaData,
    dataColorChooser: (d) =>
      [d.data.color, d.data.color2, d.data.color3][level.length],
  };
};

export const TopicsClickBurst = ({ name, data, limit = 10, level, setLevel, topics: allTopics }) => {
    const [hoveredTopic, setHoveredTopic] = useState(null);
    const { dataColorChooser, cdata } = useClickTopics({
      topics: allTopics,
      level,
      setLevel,
    });
    const onClick = useCallback(
      (node, event) => {
        setLevel((l) => (l.length < 2 ? [...l, node.id] : l));
      },
      [setLevel]
    );

    const ArcsComponent = useCallback(
      (props) => (
        <Arcs
          {...{
            ...props,
            onClick,
            setLevel,
            hoveredTopic,
            setHoveredTopic,
          }}
        />
      ),
      [onClick, setLevel, hoveredTopic]
    );

    return (
      <div style={{ width: "100%", height: "500px", color: "black" }}>
        <ResponsivePie
          data={cdata}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#ccc"
          arcLinkLabelsThickness={2}
          valueFormat=">-.1%"
          arcLabelsSkipAngle={10}
          colors={dataColorChooser}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          layers={[
            "arcLinkLabels",
            ArcsComponent,
            "legends",
            level.length > 0
              ? (props) => (
                  <Circle
                    labels={level.length > 1 ? [level[0], level[1]] : undefined}
                    label={
                      level.length === 1 ? level[level.length - 1] : undefined
                    }
                    color={
                      cdata.length > 0 &&
                      [cdata[0].color, cdata[0].color2, cdata[0].color3][
                        level.length
                      ]
                    }
                    {...props}
                    onClick={() => {
                      setLevel(level.slice(0, level.length - 1));
                    }}
                  />
                )
              : () => null,
          ]}
        />
      </div>
    );
  };
